html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins, Roboto, Arial';
  font-size: 16px;
}

/* Material UI sm breakpoint. */
@media (max-width: 960px) {
  html,
  body {
    font-size: 15px;
  }
}

/* Material UI xs breakpoint. */
@media (max-width: 600px) {
  html,
  body {
    font-size: 14px;
  }
}

#root {
  min-height: 100vh;
  flex-grow: 1;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}
/*PULSE ANIMATION*/

.pulse {
  animation: pulse 1s infinite;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
