@import '~stream-chat-react/dist/css/v2/index.css';

.str-chat {
  --str-chat__primary-color: #2c4465;
}

.str-chat__container,
.str-chat__main-panel {
  max-height: 500px;
}

.str-chat__channel-preview-messenger {
  display: none;
}
