/**
 * Use of `DropzoneArea` from `material-ui-dropzone` does not offer an API to
 * style nested `react-dropzone` child components directly.
 *
 * It does however have an API to pass CSS class names to the dropzone
 * container and text -- that forces us to put these styles in the global scope
 * rather than using the styled-component pattern.
 * 
 * https://github.com/Yuvaleros/material-ui-dropzone#dropzonearea-component-properties
 */

.curi-dropzone-container {
  background-color: rgba(44,68,101, .1) !important;
  border: 1px dashed #2C4465 !important;
}

.curi-dropzone-paragraph {
  font-size: 18px !important;
  color: #2c4465;
}

.curi-dropzone-roster-container {
  height: 250px;
}

.curi-dropzone-roster-paragraph {
  margin: 82px 0 7px;
}

.curi-dropzone-document-container {
  min-height: 85px !important;
}

.curi-dropzone-document-paragraph {
  margin-top: 5px;
  margin-bottom: 0;
}